import { useEffect, useState } from 'react'
import { trackPageView } from '../../util/analytics'

type GetTrackingURL = (page: number) => string
/**
 *
 * Legacy web app loads limit=50 gifs at a time. We can't do that with SSR otherwise
 * our response body would be too large. We want to do smaller increments of 25 after starting
 * with 10 in the response body
 *
 * @param gaTrackingUrl the url we want to fire when we paginate /trending-gifs/page/2
 * @param gifCount the total number of gifs in this grid
 */
export function useGAPagination(gaTrackingUrl?: GetTrackingURL, gifCount: number = 0, pageCount: number = 50) {
    const [hasReported, setHasReported] = useState([0, 1])
    useEffect(() => {
        if (gaTrackingUrl) {
            // we already saw the first pageview
            const page = Math.floor(gifCount / pageCount) + 1
            if (!hasReported.includes(page)) {
                setHasReported([...hasReported, page])
                trackPageView(gaTrackingUrl(page))
            }
        }
    }, [gaTrackingUrl, gifCount, hasReported, pageCount])
}
