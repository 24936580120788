'use client'
import styled from 'styled-components'
import { desktopSearchbarPadding, searchbarHeight } from './constants'
import { giphyUrl } from '@/app/util/env'

const TakeoverContainer = styled.a`
    position: absolute;
    display: block;
    right: ${searchbarHeight}px;
    top: ${desktopSearchbarPadding}px;
    cursor: pointer;
`

const TakeoverGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: 100%;
`

const Takeover = () => {
    return (
        <TakeoverContainer href="https://abc.com/show/2294c465-f2ed-45e0-954f-d994c0efc1dc" target="_blank">
            <img
                src={`${giphyUrl}/static/img/takeovers/bachelorette.png`}
                height={searchbarHeight}
                width={400}
                alt=""
            />
            <TakeoverGradient />
        </TakeoverContainer>
    )
}
export default Takeover
