'use client'

import { ReactNode, useContext } from 'react'
import UAParserContext from '../../context/ua-parser'
import HeaderSearchBar from '../../components/search-bar'
import { publicRuntimeConfig } from '@/app/util/env'
import { usePathname, useSearchParams } from 'next/navigation'

const takeOverStart = new Date('2024-07-08T02:00:00Z').getTime()
const takeOverEnd = new Date('2024-07-09T07:00:00Z').getTime()

type Props = { children: ReactNode; initialTerm?: string; countryCode?: string }
const HeaderInit = ({ children, initialTerm, countryCode }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const pathname = usePathname()
    const params = useSearchParams()

    let isEnabled

    // allow a query param to enable the takeover
    if (!!params.get('takeover')) {
        isEnabled = true
    } else {
        // only in the us
        const isUS = countryCode === 'US'
        // within this time frame
        const isScheduled = Date.now() > takeOverStart && Date.now() < takeOverEnd
        // only the home page
        const isHomepage = pathname === '/'
        // only on desktop
        const isDesktop = deviceType === 'desktop'
        isEnabled = isUS && isScheduled && isHomepage && isDesktop
    }

    return (
        <HeaderSearchBar
            hsaTakeover={isEnabled}
            initialTerm={initialTerm}
            apiKey={deviceType === 'mobile' ? publicRuntimeConfig.mobileApiKey : publicRuntimeConfig.apiKey}
        >
            {children}
        </HeaderSearchBar>
    )
}
export default HeaderInit
