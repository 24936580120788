'use client'
import { IChannel } from '@giphy/js-types'
import {
    getGADataLayerData,
    getInitialGADataLayerData,
    getUser,
    getUserLoginParameters,
    setCreator,
    setGADataLayer,
    setUser,
    UserOptions,
} from 'analytics'
import { useContext, useEffect } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import UserContext from '../context/user-context'
import Logger from './log'

export type Didomi = {
    getUserConsentStatusForVendor: Function
}
export const trackPageView = (trackingUrl: string) => {
    if (trackingUrl) {
        Logger.debug(`trackPageView virtualPageURL: ${trackingUrl}`)
        window.didomiOnReady.push(() => {
            Logger.debug(`SEND: trackPageView virtualPageURL: ${trackingUrl}`)
            window.dataLayer.push({
                event: 'VirtualPageview',
                virtualPageURL: trackingUrl,
                virtualPageName: trackingUrl,
                ...getGADataLayerData(),
            })
        })
    }
}

export const useGA4 = ({ channel }: { channel?: IChannel } = {}) => {
    const loginParams = getUserLoginParameters()
    const [loginMethod, setLoginMethod] = useLocalStorage('login_type', loginParams.platform || 'email')
    const { user, hasFetchedUser } = useContext(UserContext)
    useEffect(() => {
        // these will be undefined if we are not on a channel page
        setCreator({
            creatorId: channel ? `${channel.id}` : undefined,
            creatorName: channel?.user.username,
        })
    }, [channel])
    useEffect(() => {
        if (hasFetchedUser) {
            let userProps: UserOptions = getUser()
            if (user) {
                userProps = {
                    userID: `${user.id}`,
                    userType: user.is_staff ? 'staff' : user.user_type,
                    loginMethod: loginParams.platform || loginMethod,
                    // @ts-ignore not sure why we're tracking this and it doesn't exist on /login/check
                    dashboardAnalytics: user.permissions.dashboard_analytics,
                    verifiedStatus: user.is_verified,
                    loggedInStatus: true,
                }
            }
            setUser(userProps)
            if (loginParams.action) {
                setLoginMethod(loginParams.platform)
                setGADataLayer({
                    event: `${loginParams.action}_form_success`,
                    options: {
                        form: {
                            formName: loginParams.action,
                        },
                    },
                })
            }
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(getInitialGADataLayerData())
        }
        // TODO this only fires once on page load, but not for SPA navigation
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFetchedUser])
}
