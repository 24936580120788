'use client'

import { IGif } from '@giphy/js-types'
import { Grid as SDKGrid } from '@giphy/react-components'
import dynamic from 'next/dynamic'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import styled from 'styled-components'
import { desktopWidth } from 'ui/src/constants'
import { desktop } from 'ui/src/css'
import UAParserContext from '../../context/ua-parser'
import { relativeGifClick } from '../../util/url'
import useClientRender from '../../util/use-client-render'
import { useGAPagination } from './ga'

const GifOverlay = dynamic(() => import('./overlay/gif-overlay'), { ssr: false })

const NoGifsMessage = styled.p`
    text-align: center;
    padding: 10px;
`

type Props = Pick<SDKGrid['props'], 'initialGifs' | 'fetchGifs' | 'onGifClick' | 'overlay'> & {
    gaTrackingUrl?: (page: number) => string
    noShowMessage?: ReactNode
    columnOffsets?: number[]
    width?: number
    columns?: number
}

type GifsContextProps = {
    gifs: IGif[] | undefined
    setGifs: Dispatch<SetStateAction<IGif[] | undefined>>
}

export const GifsContext = createContext({
    gifs: [],
    setGifs: () => {},
} as GifsContextProps)

const Grid = ({
    initialGifs,
    fetchGifs,
    gaTrackingUrl,
    width,
    columns,
    noShowMessage = <>No results found.</>,
    columnOffsets,
    onGifClick,
    overlay,
}: Props) => {
    const [hasGifsToShow, setHasGifsToShow] = useState(true)
    const [externalGifs, setExternalGifs] = useState<IGif[] | undefined>()
    const [gifCount, setGifCount] = useState(0)
    useGAPagination(gaTrackingUrl, gifCount)
    const { deviceType } = useContext(UAParserContext)
    // for dynamic search
    // const { searchKey } = useContext(SearchContext)
    let { width: windowWidth } = useWindowSize()
    // no way to do grids on mobile yet since we don't know window width on server
    const canRender = useClientRender()
    // go by server ua parsing
    let isDesktop = deviceType === 'desktop'
    // switch to window width on client
    if (canRender) {
        isDesktop = windowWidth >= desktop.breakpointWidth
    }

    // fetch gifs in advance of 250px so you don't see the loader if you slow scroll
    const loaderConfig = useMemo(() => (canRender ? { rootMargin: '0px 0px 250px 0px' } : undefined), [canRender])

    const value = useMemo(() => ({ gifs: externalGifs, setGifs: setExternalGifs }), [externalGifs, setExternalGifs])

    if (!hasGifsToShow) {
        return <NoGifsMessage>{noShowMessage}</NoGifsMessage>
    }
    return isDesktop || loaderConfig ? (
        <GifsContext.Provider value={value}>
            <SDKGrid
                columns={columns || (isDesktop ? 4 : 2)}
                width={width || (isDesktop ? desktopWidth : windowWidth)}
                fetchGifs={async (offset) => {
                    const result = await fetchGifs(offset)
                    if (result.data.length === 0 && initialGifs?.length === 0) {
                        setHasGifsToShow(false)
                    }
                    return result
                }}
                externalGifs={externalGifs}
                initialGifs={initialGifs}
                onGifClick={(gif, event) => {
                    if (!onGifClick) {
                        relativeGifClick(gif, event)
                    } else {
                        onGifClick(gif, event)
                    }
                }}
                gutter={isDesktop ? 12 : undefined}
                overlay={overlay || (isDesktop ? GifOverlay : undefined)}
                hideAttribution
                loaderConfig={loaderConfig}
                onGifsFetched={(gifs) => {
                    setGifCount(gifs.length)
                    setExternalGifs(gifs)
                }}
                columnOffsets={columnOffsets}
            />
        </GifsContext.Provider>
    ) : null
}

export default Grid
